@import './mixins.scss';

html,
body {
  @include size(100vw, 100vh);
  position: relative;
  font-size: 10px;
  background-color: $beige;
  color: $black;
  overflow-x: hidden;

  h1 {
    font-family: $hanson;
    font-size: 4rem;
    line-height: 125%;
  }

  h3 {
    font-family: $deutschlander;
  }

  a,
  button {
    text-decoration: none;
    border-style: none;
    cursor: pointer;
    background-color: transparent;
  }

  a,
  button,
  textarea {

    &:focus,
    &:active,
    &:visited,
    &:link,
    &:hover {
      text-decoration: none;
      color: inherit;
      outline: none;
    }
  }

  img {
    display: block;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}
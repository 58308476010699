@import '../../styles/mixins.scss';

.loading {
  @include flex(column, center, center);
  background-color: $beige;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 3;
}
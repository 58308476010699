@import '../../styles/mixins.scss';

.contentContainer {
  @include size(100%, 100%);
  @include flex($justify: center);
  position: absolute;
  background-image: url('../../assets/background.jpg');
  background-size: 100% 100%;
  overflow: hidden;
  padding: 0 4rem;

  @include tablet {
    @include flex(column-reverse, flex-start, center);
    padding: 0;
    background-size: cover;
  }

  @include mobile {
    background-image: url('../../assets/backgroundmobile.jpg');
    background-size: 100% 100%;
  }

  .playerContainer {
    @include size(50%, 100%);
    @include flex(column, flex-end, flex-end);
    position: relative;
    padding: 0 4rem;

    @include tablet {
      @include size(100%, fit-content);
      @include flex(column, center, center);
      padding: 0;
    }

    img {
      width: 55rem;

      @include tablet {
        width: auto;
        height: 55vh;
      }

      @include mobile {
        height: 50vh;
      }
    }

    button {
      @include flex();
      gap: 0.5rem;
      position: absolute;
      top: 4rem;
      left: 0;
      padding: 2rem;
      color: $black;

      @include tablet {
        left: -1rem;
        top: unset;
        bottom: 0;
      }

      @include mobile {
        left: 0;
      }

      &:hover {
        background-image: url('../../assets/dourado.png');
        background-size: 100% 100%;
      }

      img {
        width: 2.5rem;
        height: fit-content;

        @include mobile {
          width: 3rem;
        }
      }

      span {
        font-family: $hanson;
        font-size: 1.4rem;

        @include mobile {
          display: none;
        }
      }
    }
  }

  .formContainer {
    @include size(50%, 100%);
    @include flex(column, center, flex-start);
    padding-right: 4rem;

    @include tablet {
      @include flex(column, center, center);
      @include size(100%, fit-content);
      position: unset;
      padding: 0;
    }

    img {
      width: 60rem;
      margin-left: -2rem;

      @include tablet {
        @include size(auto, 25vh);
        margin: 0;
        padding: 0;
      }

      @include mobile {
        @include size(35rem, auto);
      }

      @include mobileSmall {
        height: auto;
        width: 30rem;
      }
    }
  }

  .hashtag {
    position: fixed;
    width: 15rem;
    right: 0;
    bottom: -2rem;

    @include mobile {
      width: 12rem;
      bottom: -3rem;
    }
  }
}
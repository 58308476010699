@import '../../styles/mixins.scss';

.form {
  width: 57.5rem;
  padding: 0 4rem;
  margin-left: -0.5rem;

  @include tablet {
    width: 53rem;
    margin: 0;
  }

  @include mobile {
    width: 37.5rem;
  }

  @include mobileSmall {
    width: 33rem;
  }

  .inputField {
    width: 100%;
    @include flex(column, center, flex-end);
    gap: 1rem;
    padding: 2rem 0;

    textarea {
      width: 100%;
      padding: 0;
      background: transparent;
      border-style: none;
      border-bottom: 0.25rem solid #1f1f1f;
      font-family: $hanson;
      font-size: 4rem;
      text-transform: uppercase;
      min-height: 1em;
      overflow: auto;
      hyphens: auto;
      resize: none;

      @include tablet {
        font-size: 2rem;
      }

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }

  button {
    background: $darkgray;
    border: 0.25rem solid $darkgray;
    font-family: $hanson;
    font-size: 1.4rem;
    color: white;
    padding: 1rem 2rem;

    &:hover {
      background: transparent;
      color: $darkgray;
    }
  }
}
@import '../../styles/mixins.scss';

.resultModal {
  @include flex(column, space-between, center);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 5rem 2rem;
  background: $darkgray;
  color: white;
  text-align: center;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;

  @include tablet {
    @include flex(column, flex-start, center);
    padding: 5rem 0 0 0;
  }

  >h1 {
    font-size: 1.6rem;
    width: 80%;
  }

  .resultContainer {
    height: auto;
    padding: 2rem;
    transform-origin: top center;

    @include tablet {
      height: fit-content;
    }

    .resultPoster,
    .resultPosterPreview {
      background-image: url('../../assets/background.jpg');
      background-size: 100% 100%;
      width: 100rem;
      height: 50rem;
      position: relative;
      margin: 2rem 0;
      overflow: hidden;
      transform-origin: top center;

      &.resultPosterPreview {
        display: none;
      }

      @include tablet {
        &.resultPoster {
          transform: translateX(200vw);
          position: absolute;
        }

        &.resultPosterPreview {
          display: block;
          transform: scale(0.6);
        }
      }

      @include mobile {
        &.resultPosterPreview {
          display: block;
          transform: scale(0.35);
          transform-origin: top;
        }
      }

      .playerImage {
        width: 37.5rem;
        height: auto;
        position: sticky;

        left: 6.5rem;
        margin-top: 3.2rem;
      }

      .messageWrapper {
        position: absolute;
        height: 100%;
        top: 0;
        right: 6.5rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 45rem;
          height: auto;
          margin-top: -5rem;
        }

        h1 {
          color: $darkgray;
          width: 37.5rem;
          margin: -0.2rem 0 0 2.5rem;
          font-size: 3.2rem;
          text-align: left;
          text-transform: uppercase;
          position: sticky;
          top: 0;

          @include tablet {
            margin-left: 4.4rem;
          }
        }
      }

      .tag {
        position: absolute;
        width: 12.5rem;
        height: auto;
        right: 0;
        bottom: -3rem;
      }
    }

    .resultPosterStory,
    .resultPosterStoryPreview {
      background-image: url('../../assets/backgroundmobile.jpg');
      background-size: 100% 100%;
      width: 27rem;
      height: 48rem;
      position: relative;
      overflow: hidden;
      transform-origin: top center;

      &.resultPosterStoryPreview {
        display: none;
      }

      @include tablet {
        &.resultPosterStory {
          transform: translateX(200vw);
          position: absolute;
        }

        &.resultPosterStoryPreview {
          display: block;
        }
      }

      @include mobile {
        &.resultPosterStoryPreview {
          display: block;
          transform: scale(0.8);
          transform-origin: top;
        }
      }

      .playerImage {
        width: 20rem;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }

      .messageWrapper {
        width: 100%;
        position: absolute;
        top: 4rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 25rem;
          height: auto;
          margin-left: 0.1rem;
        }

        h1 {
          color: $darkgray;
          width: 20rem;
          margin: -0.1rem 0 0 .5rem;
          font-size: 1.6rem;
          text-align: left;
          text-transform: uppercase;
        }
      }

      .tag {
        position: sticky;
        width: 7.5rem;
        height: auto;
        top: 100%;
        left: 0.25rem;
        margin-bottom: -1rem;
      }
    }

    .resultPosterSquare,
    .resultPosterSquarePreview {
      background-image: url('../../assets/background.jpg');
      background-size: cover;
      width: 50rem;
      height: 50rem;
      position: relative;
      overflow: hidden;
      transform-origin: top center;

      &.resultPosterSquarePreview {
        display: none;
      }

      @include tablet {
        &.resultPosterSquare {
          transform: translateX(200vw);
          position: absolute;
        }

        &.resultPosterSquarePreview {
          display: block;
          transform: scale(0.8);
        }
      }

      @include mobile {
        &.resultPosterSquarePreview {
          display: block;
          transform: scale(0.6);
          transform-origin: top;
        }
      }

      .playerImage {
        width: 37.5rem;
        height: auto;
        position: absolute;
        bottom: 0;
        left: -13.5rem;
        margin: 0;
      }

      .messageWrapper {
        width: fit-content;
        position: absolute;
        top: 13rem;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 32rem;
          height: auto;
          margin-left: 0.1rem;
        }

        h1 {
          color: $darkgray;
          width: 25rem;
          margin: -0.2rem 0 0 .5rem;
          font-size: 2rem;
          text-align: left;
          text-transform: uppercase;
        }
      }

      .tag {
        position: absolute;
        width: 12.5rem;
        height: auto;
        right: 0.25rem;
        bottom: -3rem;
      }
    }
  }

  .btnWrapper {
    @include flex($justify: space-between);
    width: 100%;
    padding: 0 10rem;
    gap: 2rem;

    @include tablet {
      padding: 2rem;
      position: sticky;
      bottom: 0;
      background: rgba($darkgray, 0.98);
    }

    @include mobile {
      @include flex(column, center, center);
    }

    .posterStyles {
      display: flex;
      align-items: flex-end;
      gap: 1rem;

      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        color: #fff;

        &:hover {
          opacity: 0.5;
        }

        div {
          border: 0.1rem solid white;
        }

        &:first-child {

          div {
            width: 40px;
            height: 20px;
          }
        }

        &:nth-child(2) {
          div {
            width: 20px;
            height: 20px;
          }
        }

        &:last-child {
          div {
            width: 20px;
            height: 40px;
          }
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      gap: 1rem;

      .download {
        background: $darkgray;
        border: 0.25rem solid white;
        font-family: $hanson;
        font-size: 1.4rem;
        color: white;
        padding: 1rem 2rem;

        &:hover {
          background: white;
          color: $darkgray;
        }
      }

      a {
        font-family: $hanson;
        font-size: 1.4rem;
        opacity: 0.5;
      }
    }
  }
}
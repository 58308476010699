@import './variables.scss';

@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin size($width: $width, $height: $height) {
  width: $width;
  height: $height;
}

@mixin mobileSmall {
  @media only screen and (max-width: 330px) {
    @content;
  }
}


@mixin mobile {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@font-face {
  font-family: Hanson-Bold;
  src: url('../assets/fonts/Hanson-Bold.ttf');
}

@font-face {
  font-family: Deutschlander;
  src: url('../assets/fonts/Deutschlander20.ttf');
}

$black: #000000;
$darkgray: #161616;
$gold: #ffb300;
$beige: #ece8e5;

$hanson: 'Hanson-Bold';
$deutschlander: 'Deutschlander';